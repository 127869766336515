@font-face {
    font-family: 'MyCustomFont';
    src: url('/public/assets/fonts/Rubik-VariableFont_wght.ttf');
}

:root {
    --color-neon-pitch-green: rgba(85, 244, 142, 1);
    --color-legacy-blue: rgba(8, 74, 255, 1);
    --color-expert-purple: rgba(198, 66, 245, 1);
    --color-passionate-red: rgba(255, 20, 90, 1);
    --color-united-gold: rgba(255, 211, 0, 1);
    --color-dynamic-blue: rgba(22, 222, 229, 1);
    --color-heri-blue-10: rgba(230, 237, 255, 1);
    --color-heri-blue-20: rgba(206, 219, 255, 1);
    --color-heri-blue-30: rgba(181, 201, 255, 1);
    --color-heri-blue-40: rgba(156, 183, 255, 1);
    --color-heri-blue-50: rgba(131, 164, 255, 1);
    --color-heri-blue-60: rgba(107, 146, 255, 1);
    --color-heri-blue-70: rgba(82, 128, 255, 1);
    --color-heri-blue-80: rgba(57, 110, 255, 1);
    --color-heri-blue-90: rgba(33, 92, 255, 1);
    --color-purple-10: rgba(249, 236, 254, 1);
    --color-purple-20: rgba(244, 217, 253, 1);
    --color-purple-30: rgba(238, 198, 252, 1);
    --color-purple-40: rgba(232, 179, 251, 1);
    --color-purple-50: rgba(227, 160, 250, 1);
    --color-purple-60: rgba(221, 142, 249, 1);
    --color-purple-70: rgba(215, 123, 248, 1);
    --color-purple-80: rgba(209, 104, 247, 1);
    --color-purple-90: rgba(204, 85, 246, 1);
    --color-red-20: rgba(255, 208, 222, 1);
    --color-red-30: rgba(255, 184, 205, 1);
    --color-red-40: rgba(255, 161, 189, 1);
    --color-red-50: rgba(255, 137, 172, 1);
    --color-red-60: rgba(255, 114, 156, 1);
    --color-red-70: rgba(255, 91, 139, 1);
    --color-red-80: rgba(255, 67, 123, 1);
    --color-red-90: rgba(255, 44, 107, 1);
    --color-united-gold-10: rgba(255, 251, 229, 1);
    --color-united-gold-20: rgba(255, 246, 204, 1);
    --color-united-gold-30: rgba(255, 242, 178, 1);
    --color-united-gold-40: rgba(255, 237, 153, 1);
    --color-united-gold-50: rgba(255, 233, 128, 1);
    --color-united-gold-60: rgba(255, 229, 102, 1);
    --color-united-gold-70: rgba(255, 224, 77, 1);
    --color-united-gold-80: rgba(255, 220, 51, 1);
    --color-united-gold-90: rgba(255, 215, 26, 1);
    --color-neon-pitch-green-10: rgba(238, 254, 244, 1);
    --color-neon-pitch-green-20: rgba(221, 253, 232, 1);
    --color-neon-pitch-green-30: rgba(204, 252, 221, 1);
    --color-neon-pitch-green-40: rgba(187, 251, 210, 1);
    --color-neon-pitch-green-50: rgba(170, 249, 199, 1);
    --color-neon-pitch-green-60: rgba(153, 248, 187, 1);
    --color-neon-pitch-green-70: rgba(136, 247, 176, 1);
    --color-neon-pitch-green-80: rgba(119, 246, 165, 1);
    --color-neon-pitch-green-90: rgba(102, 245, 153, 1);
    --color-dynamic-blue-10: rgba(232, 254, 255, 1);
    --color-dynamic-blue-20: rgba(209, 253, 255, 1);
    --color-dynamic-blue-30: rgba(186, 253, 255, 1);
    --color-dynamic-blue-40: rgba(163, 252, 255, 1);
    --color-dynamic-blue-50: rgba(140, 251, 255, 1);
    --color-dynamic-blue-60: rgba(117, 250, 255, 1);
    --color-dynamic-blue-70: rgba(94, 249, 255, 1);
    --color-dynamic-blue-80: rgba(71, 249, 255, 1);
    --color-dynamic-blue-90: rgba(48, 248, 255, 1);
    --color-grey-10: rgba(204, 204, 204, 1);
    --color-grey-20: rgba(153, 153, 153, 1);
    --color-grey-30: rgba(56, 56, 56, 1);
    /* Text-size styles
    /* base size: desktop-para-16--regular (16px) */
    --desktop-h1: 6.25rem;
    --desktop-h2: 3.38rem;
    --desktop-h3: 2.62rem;
    --desktop-h4: 2.25rem;
    --desktop-h5: 1.5rem;
    --desktop-h6: 1.875rem;
    --mobile-h1: 2.88rem;
    --mobile-h2: 2.38rem;
    --mobile-h3: 1.88rem;
    --mobile-h4: 1.5rem;
    --mobile-h5: 1.25rem;
    --mobile-h6: 1rem;
    --desktop-mega: 8.12rem;
    --desktop-approach-special: 1.88rem;
    --desktop-para-48: 3rem;
    --desktop-para-36: 2.25rem;
    --desktop-para-24: 1.5rem;
    --desktop-para-20: 1.25rem;
    --desktop-para-18: 1.12rem;
    --desktop-para-16: 1rem;
    --desktop-kicker--large: 1.5rem;
    --desktop-kicker--medium: 1.25rem;
    --desktop-kicker--small: 1.12rem;
    --desktop-category--title: 0.94rem;
    --desktop-date--title: 0.81rem;
    --desktop-accordion: 1rem;
    --desktop-quote: 2.25rem;
    --desktop-stat: 5rem;
    --mobile-mega: 3.1rem;
    --mobile-approach-special: 1.25rem;
    --mobile-para-32: 2rem;
    --mobile-para-24: 1.5rem;
    --mobile-para-20--regular: 1.25rem;
    --mobile-para-20--semibold: 1.25rem;
    --mobile-para-18--regular: 1.12rem;
    --mobile-para-18--semibold: 1.12rem;
    --mobile-para-16--regular: 1rem;
    --mobile-para-16--semibold: 1rem;
    --mobile-para-14--regular: 0.88rem;
    --mobile-para-14--semibold: 0.88rem;
    --mobile-kicker--large: 1.12rem;
    --mobile-kicker--medium: 1rem;
    --mobile-kicker--small: 0.88rem;
    --mobile-category--title: 0.88rem;
    --mobile-date--title: 0.75rem;
    --mobile-stat: 4.38rem;
    --mobile-quote: 1.5rem;
    /* Fonts */
    --font-primary: "ProximaNova", sans-serif;
    --font-secondary: "ApproachMono", sans-serif;
    --font-size-base: 1.125rem;
    --line-height-base: 1.75rem;
    --mobile-font-size-base: 1rem;
    --mobile-line-height-base: 1.625rem;
    /* Header */
    --header-height-sm: 55px;
    --header-height: 86px;
    --header-bg-color: var(--bs-black);
    --logo-height: 50px;
    --logo-height-sm: 30px;
    --height-small: 35px;
    --color-header-border: rgba(204, 204, 204, 0.4);
    /* Bootstrap */
    --bs-dropdown-box-shadow: none;
    --bs-nav-link-font-weight: 700;
    --bs-modal-color: #000;
    --bs-popover-header-color: #000;
}

:root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #c642f5;
    --bs-pink: #d63384;
    --bs-red: #ff145a;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-black: #000;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: rgb(255, 211, 0);
    --bs-secondary: rgb(85, 244, 142);
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #ff145a;
    --bs-light: #fff;
    --bs-dark: #000;
    --bs-grey: rgb(153, 153, 153);
    --bs-primary-rgb: 255, 211, 0;
    --bs-secondary-rgb: 85, 244, 142;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 255, 20, 90;
    --bs-light-rgb: 255, 255, 255;
    --bs-dark-rgb: 0, 0, 0;
    --bs-grey-rgb: 153, 153, 153;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 0, 0, 0;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #000;
    --bs-body-bg: #fff;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #dee2e6;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --bs-border-radius: 0.375rem;
    --bs-border-radius-sm: 0.25rem;
    --bs-border-radius-lg: 0.5rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-2xl: 2rem;
    --bs-border-radius-pill: 50rem;
    --bs-link-color: rgb(8, 74, 255);
    --bs-link-hover-color: #063bcc;
    --bs-code-color: #d63384;
    --bs-highlight-bg: #fff3cd;
}

body {
    font-family: 'MyCustomFont', sans-serif;
    overflow-x: hidden;
    background-color: white;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    /* width: 10px;
    background-color: transparent; */
    display: none;
}

/* ::-webkit-scrollbar-track {
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    background: #2CA95D;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #32aa62;
} */


/***********************************************************************************home***********************************************************************************************************/

.image-cross-cut {
    width: 100%;
    height: auto;
    clip-path: polygon(40% 0%, 100% 0%, 100% 100%, 0% 100%);
    z-index: 1;
}

@media (min-width: 1024px) {
    .header-lineHeight {
        line-height: 4rem;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .header-lineHeight {
        line-height: 3.5rem;
    }
}

@media (max-width: 767px) {
    .header-lineHeight {
        line-height: 2.5rem;
    }
}

/* .image-animate {
    animation: slideDoor 4s ease-in-out forwards;
}

@keyframes slideDoor {
    0% {
        clip-path: inset(0 0 0 100%);
    }

    100% {
        clip-path: inset(0 0 0 0);
    }
} */

/***********************************************************************************sports***********************************************************************************************************/

.tilt-box-wrap {
    position: relative;
    transition: all 0.6s ease-out;
    perspective: 1000px;
}

.tilt-box {
    transition: all 0.6s ease-out;
    transform: rotateX(0deg) rotateY(0deg);
    perspective: 1000px;
    transform-style: preserve-3d;
}



.t-over {
    width: 33.333%;
    height: 33.333%;
    position: absolute;
    z-index: 1;
}

.t-over:nth-child(1) {
    left: 0;
    top: 0
}

.t-over:nth-child(2) {
    left: 33.333%;
    top: 0
}

.t-over:nth-child(3) {
    left: 66.666%;
    top: 0
}

.t-over:nth-child(4) {
    left: 0;
    top: 33.333%
}

.t-over:nth-child(5) {
    left: 33.333%;
    top: 33.333%
}

.t-over:nth-child(6) {
    left: 66.666%;
    top: 33.333%
}

.t-over:nth-child(7) {
    left: 0;
    top: 66.666%
}

.t-over:nth-child(8) {
    left: 33.333%;
    top: 66.666%
}

.t-over:nth-child(9) {
    left: 66.666%;
    top: 66.666%
}

.t-over:nth-child(1):hover~.tilt-box {
    transform: rotateX(20deg) rotateY(-20deg);
    filter: brightness(1);
}

.t-over:nth-child(2):hover~.tilt-box {
    transform: rotateX(30deg) rotateY(0deg);
    filter: brightness(1.25);
}

.t-over:nth-child(3):hover~.tilt-box {
    transform: rotateX(20deg) rotateY(20deg);
    filter: brightness(1.10);
}

.t-over:nth-child(4):hover~.tilt-box {
    transform: rotateX(0deg) rotateY(-20deg)
}

.t-over:nth-child(5):hover~.tilt-box {
    transform: rotateX(0deg) rotateY(0deg)
}

.t-over:nth-child(6):hover~.tilt-box {
    transform: rotateX(0deg) rotateY(20deg)
}

.t-over:nth-child(7):hover~.tilt-box {
    transform: rotateX(-20deg) rotateY(-20deg);
    filter: brightness(0.80);
}

.t-over:nth-child(8):hover~.tilt-box {
    transform: rotateX(-20deg) rotateY(0deg);
    filter: brightness(0.60);
}

.t-over:nth-child(9):hover~.tilt-box {
    transform: rotateX(-20deg) rotateY(20deg);
    filter: brightness(0.60);
}

.sports-image-cross-cut {
    width: 100%;
    height: auto;
    clip-path: polygon(40% 0%, 100% 0%, 100% 100%, 0% 100%);
    z-index: 1;
}

/*************************************************************************************world 11 token******************************************************************************************************/
main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    gap: 30px;
    padding: 40px 80px;
}

.card {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: min(40%, 650px);
    height: 400px;
    padding: 30px;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease;
}

@media (max-width:1023px) {
    .card {
        width: min(50%, 650px);
    }
}

/* .card:hover {
    transform: translateY(10px);
} */

@media (max-width: 890px) {
    .card {
        width: 100%;

    }
}

.card:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg,
            rgba(85, 244, 142, 0.75) 1%,
            rgba(85, 244, 142, 0.75) 98%);
    transition: all 0.5s;
    opacity: 0;
    /* border-radius: 20px; */
}

.card:hover:after {
    opacity: 1;
}

.card img {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    /* border-radius: 20px; */
}

.card .info {
    position: relative;
    z-index: 3;
    color: #fff;
    opacity: 0;
    transform: translateY(40px);
    transition: all 0.5s;
    user-select: none;
}

.card:hover .info {
    opacity: 1;
    transform: translateY(0);
}

.info p {
    margin: 24px 0 16px;
    line-height: 1.3;
    cursor: default;
}

@media (max-width: 890px) {
    .logo {
        right: -10px;
        bottom: -20px;
    }

    .logo img {
        width: 80px;
    }
}

@media (max-width: 930px) {
    main {
        grid-template-columns: repeat(1, 1fr);
        padding: 60px 80px;
    }
}

@media (max-width: 400px) {
    main {
        padding: 60px 30px;
    }

    .card {
        height: 400px;
        padding: 20px;
    }
}

.world11token-image-cross-cut {
    width: 100%;
    height: auto;
    clip-path: polygon(0 0, 50% 0, 100% 100%, 0 100%);
    z-index: 1;
}



/***********************************************************************************how it works***********************************************************************************************************/

.works-image-cross-cut {
    width: 100%;
    height: auto;
    clip-path: polygon(0 0, 30% 0, 60% 100%, 0 100%);
    z-index: 1;
    position: relative;
}

.polygon-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: white;
    /* Text color */
    font-size: 3.38rem;
    font-weight: medium;
    text-align: center;
}

.bg-united-gold {
    background-color: var(--color-united-gold-80);
}

.blogCard {
    position: relative;
    width: 100%;
    aspect-ratio: 14/14;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    cursor: pointer;
}

.blogCard img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    transition: 0.4s;
}

.blogCard:hover img {
    transform: scale(1.05);
}

.no-scroll {
    overflow: hidden;
}